<template>
  <div class="card">
    <div class="card-header border-0 pb-0">
      <div
        class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
      >
        <h4>
          <i class="ti ti-grip-vertical me-1"></i>Общая сумма вознаграждений
        </h4>
        <div class="d-flex align-items-center flex-wrap row-gap-2">
          <div class="dropdown">
            <a
              class="dropdown-toggle d-flex align-items-center gap-2"
              data-bs-toggle="dropdown"
              href="javascript:void(0);"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <g clip-path="url(#clip0_2088_6107)">
                  <path
                    d="M6.10449 6.72754H7.10972V11.1097"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60205 11.1099H8.61773"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.58091 2.83252C1.31431 2.83252 1.05862 2.93512 0.870108 3.11775C0.681591 3.30037 0.575684 3.54807 0.575684 3.80634V12.5707C0.575684 12.829 0.681591 13.0767 0.870108 13.2593C1.05862 13.442 1.31431 13.5446 1.58091 13.5446H12.6384C12.905 13.5446 13.1607 13.442 13.3492 13.2593C13.5377 13.0767 13.6436 12.829 13.6436 12.5707V3.80634C13.6436 3.54807 13.5377 3.30037 13.3492 3.11775C13.1607 2.93512 12.905 2.83252 12.6384 2.83252H10.6279"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.59131 0.884766V4.78005"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6279 0.884766V4.78005"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.59131 2.83252H8.61744"
                    stroke="#000001"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2088_6107">
                    <rect
                      width="14.0732"
                      height="13.6335"
                      fill="white"
                      transform="translate(0.0732422 0.397949)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>За 30 Дней</p>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="javascript:void(0);" class="dropdown-item">
                За 30 Дней
              </a>
              <a href="javascript:void(0);" class="dropdown-item">
                За 15 Дней
              </a>
              <a href="javascript:void(0);" class="dropdown-item">
                За 7 Дней
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div id="won-chart">
        <apexchart
          type="bar"
          height="150"
          :options="wonChart.chart"
          :series="wonChart.series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { wonChart } from "./data";

export default {
  data() {
    return {
      wonChart: wonChart,
    };
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  p {
    font-size: 12px;
  }
}
</style>
