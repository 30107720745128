<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" :count="count" :breadcrumb="breadcrumb" />
          <!-- /Page Header -->

          <div class="card">
            <div class="card-header">
              <!-- Filter -->
              <div
                class="d-flex align-items-center justify-content-between flex-wrap row-gap-2"
              >
                <div class="d-flex align-items-center flex-wrap row-gap-2">
                  <nav class="deals-nav">
                    <ul class="d-flex align-items-center gap-2">
                      <li v-for="filter in filtersData" :key="filter.value">
                        <button
                          class="btn gap-2"
                          @click="handleFilter(filter.value)"
                          :class="
                            filter.value === activeFilter
                              ? 'btn-primary'
                              : 'btn-primary-transparent'
                          "
                        >
                          <p class="mb-0">{{ filter.title }}</p>
                          <span class="badge border bg-primary-900 border-0">{{
                            filter.count
                          }}</span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div class="d-flex align-items-center flex-wrap row-gap-2">
                  <div class="form-sorts dropdown me-2">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      class="d-flex align-items-center gap-2"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2086_3304)">
                          <path
                            d="M2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.5 2H13.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 8.5C7.82843 8.5 8.5 7.82843 8.5 7C8.5 6.17157 7.82843 5.5 7 5.5C6.17157 5.5 5.5 6.17157 5.5 7C5.5 7.82843 6.17157 8.5 7 8.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M0.5 7H5.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.5 7H13.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 12H0.5"
                            stroke="#000001"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2086_3304">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs></svg
                      >Фильтр</a
                    >
                    <div
                      class="filter-dropdown-menu dropdown-menu dropdown-menu-md-end p-0"
                    >
                      <div class="filter-set-view">
                        <div class="filter-set-head p-3">
                          <h4><i class="ti ti-filter-share"></i>Фильтр</h4>
                        </div>
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content p-3">
                            <div class="filter-set-content-head">
                              <a
                                href="#"
                                class="collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#dealType"
                                aria-expanded="false"
                                aria-controls="dealType"
                                >По типу сделки</a
                              >
                            </div>
                            <div
                              class="filter-set-contents accordion-collapse collapse"
                              id="dealType"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="filter-content-list">
                                <ul>
                                  <li>
                                    <div class="filter-checks">
                                      <label class="checkboxs">
                                        <input type="checkbox" />
                                        <span class="checkmarks"></span>
                                        Лизинг
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Filter -->
            </div>
            <div class="card-body">
              <!-- Contact List -->
              <div
                v-if="
                  activeFilter === 'accepted' || activeFilter === 'finished'
                "
                class="table-responsive custom-table mb-0"
              >
                <deals-table-accepted
                  :key="activeFilter"
                  :filter="activeFilter"
                ></deals-table-accepted>
              </div>
              <!-- /Contact List -->
            </div>
          </div>
        </div>
      </div>
      <main-footer></main-footer>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref, computed } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";
import { useRequestsStore } from "@/stores/requests";
export default {
  data() {
    const requestsStore = useRequestsStore();
    const count = computed(() => requestsStore.getAcceptedDealsCount);
    return {
      title: "Сделки",
      count: count,
      breadcrumb: [
        {
          title: "Сделки",
          link: "/deals",
        },
      ],
    };
  },
  setup() {
    const dateRangeInput = ref(null);
    const requestsStore = useRequestsStore();
    const countAccepted = computed(() => requestsStore.getAcceptedDealsCount);
    const countFinished = computed(() => requestsStore.getFinishedDealsCount);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    const filtersData = ref([
      {
        title: "Приняты",
        value: "accepted",
        count: countAccepted,
      },
      {
        title: "Завершены",
        value: "finished",
        count: countFinished,
      },
    ]);

    const activeFilter = ref("accepted");

    const handleFilter = (value) => {
      activeFilter.value = value;
    };

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, "days"),
                moment().subtract(1, "days"),
              ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      activeFilter,
      dateRangeInput,
      filtersData,
      handleFilter,
    };
  },
};
</script>
