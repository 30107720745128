<template>
  <!-- Add Page -->
  <div class="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add">
    <div class="offcanvas-header border-bottom">
      <h5 class="fw-semibold">Add Page</h5>
      <button
        type="button"
        class="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Title <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Slug <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >keywords <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="col-form-label">Login Type</label>
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <input
                    type="radio"
                    class="status-radio"
                    id="top-menu1"
                    name="export-type"
                    checked=""
                  />
                  <label for="top-menu1">Top Menu</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="status-radio"
                    id="links1"
                    name="export-type"
                  />
                  <label for="links1">Quick Links</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="col-form-label">Visibility</label>
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <input
                    type="radio"
                    class="status-radio"
                    id="pdf"
                    name="export-type"
                    checked=""
                  />
                  <label for="pdf">Show</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="status-radio"
                    id="share"
                    name="export-type"
                  />
                  <label for="share">Hide</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Content<span class="text-danger">*</span></label
              >
              <textarea class="form-control" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <a href="#" class="btn btn-light me-2">Cancel</a>
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#create_page"
            class="btn btn-primary"
            >Create</a
          >
        </div>
      </form>
    </div>
  </div>
  <!-- /Add Contact -->

  <!-- Edit Contact -->
  <div class="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_edit">
    <div class="offcanvas-header border-bottom">
      <h5 class="fw-semibold">Edit Page</h5>
      <button
        type="button"
        class="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Title <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="	Home" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Slug <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="home" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Description <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >keywords <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="	home" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="col-form-label">Login Type</label>
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <input
                    type="radio"
                    class="status-radio"
                    id="top-menu"
                    name="export-type"
                    checked=""
                  />
                  <label for="top-menu">Top Menu</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="status-radio"
                    id="links"
                    name="export-type"
                  />
                  <label for="links">Quick Links</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="col-form-label">Visibility</label>
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <input
                    type="radio"
                    class="status-radio"
                    id="showa"
                    name="export-type"
                    checked=""
                  />
                  <label for="showa">Show</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="status-radio"
                    id="hides"
                    name="export-type"
                  />
                  <label for="hides">Hide</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="col-form-label"
                >Content<span class="text-danger">*</span></label
              >
              <textarea class="form-control" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <a href="#" class="btn btn-light me-2">Cancel</a>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  </div>
  <!-- /Edit page -->

  <!-- Delete page -->
  <div class="modal custom-modal fade" id="delete_page" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <div class="avatar avatar-xl bg-danger-light rounded-circle mb-3">
              <i class="ti ti-trash-x fs-36 text-danger"></i>
            </div>
            <h4 class="mb-2">Remove Pages?</h4>
            <p class="mb-0">Are you sure you want to remove it</p>
            <div class="d-flex align-items-center justify-content-center mt-4">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <router-link
                to="/content/pages"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete page -->

  <!-- Create Page -->
  <div class="modal custom-modal fade" id="create_page" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0 m-0 justify-content-end">
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon bg-light-blue">
              <i class="ti ti-user-plus"></i>
            </div>
            <h3>Page Created Successfully!!!</h3>
            <p>View the details of contact, created</p>
            <div class="col-lg-12 text-center modal-btn">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <router-link to="/content/pages" class="btn btn-primary"
                >View Details</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Create Contact -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/content/pages");
    },
  },
};
</script>
