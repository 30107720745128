<template>
  <table class="table dataTable" id="deals-project">
    <thead class="thead-light">
      <tr>
        <th>ID</th>
        <th>Дата создания</th>
        <th>Тип сделки</th>
        <th>ИНН</th>
        <th>Статус</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in DealsTable" :key="item.id">
        <td>{{ item.name }}</td>
        <td>{{ item.created_at }}</td>
        <td>
          <span :class="item.typeclass">{{ item.type }} </span>
        </td>
        <td>{{ item.inn }}</td>
        <td>
          <span :class="item.statusclass">{{
            item.status === "new"
              ? "Новая"
              : item.status === "accepted"
              ? "Принята"
              : item.status === "rejected"
              ? "Отклонена"
              : ""
          }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DealsTable from "./dataTable.js";
export default {
  data() {
    return {
      DealsTable: DealsTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.table,
.table.table.dataTable > thead > tr th {
  font-size: 12px;
}
</style>
