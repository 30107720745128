<template>
  <ul>
    <li v-for="item in side_bar_data" :key="item.tittle">
      <h6 class="submenu-hdr">
        <span>{{ item.tittle }}</span>
      </h6>
      <ul>
        <template v-for="menu in item.menu" :key="menu.menuValue">
          <li v-if="!menu.hasSubRoute">
            <router-link
              v-if="menu.route"
              :to="menu.route"
              :class="{ active: isMenuActive(menu) }"
              @click="expandSubMenus(menu)"
              ><i class="ti" :class="'ti-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span></router-link
            >
          </li>
          <li v-else class="submenu">
            <a
              href="javascript:void(0)"
              @click="expandSubMenus(menu)"
              :class="{
                subdrop: menu.showSubRoute,
                active: route_array[1] === menu.active_link,
              }"
            >
              <i class="ti" :class="'ti-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span>
              <span class="menu-arrow"></span>
            </a>
            <ul
              :class="{
                'd-block': menu.showSubRoute,
                'd-none': !menu.showSubRoute,
              }"
            >
              <li v-for="subMenu in menu.subMenus" :key="subMenu.menuValue">
                <router-link
                  :to="subMenu.route"
                  :class="{
                    active:
                      currentPath === subMenu.active_link ||
                      currentPath === subMenu.active_link1 ||
                      currentPath === subMenu.active_link2 ||
                      currentPath === subMenu.active_link3 ||
                      currentPath === subMenu.active_link4 ||
                      currentPath === subMenu.active_link5 ||
                      currentPath === subMenu.active_link6 ||
                      currentPath === subMenu.active_link7 ||
                      currentPath === subMenu.active_link8 ||
                      currentPath === subMenu.active_link9 ||
                      currentPath === subMenu.active_link10 ||
                      currentPath === subMenu.active_link11,
                  }"
                >
                  {{ subMenu.menuValue }}
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="menu.hasSubRouteTwo" class="submenu">
            <a
              href="javascript:void(0);"
              @click="openMenu(menu)"
              :class="{
                subdrop: menu.menuValue === 'Application' && isMenuActive(menu),
                active: isMenuActive(menu),
              }"
            >
              <i class="ti" :class="'ti-' + menu.icon"></i>
              <span>{{ menu.menuValue }}</span>
              <span class="menu-arrow"></span>
            </a>

            <ul
              :class="{
                'd-block': openMenuItem === menu,
                'd-none': openMenuItem !== menu,
              }"
            >
              <template v-for="subMenus in menu.subMenus" :key="subMenus.menuValue">
                <li v-if="!subMenus.customSubmenuTwo">
                  <router-link :to="subMenus.route" @click="expandSubMenus(subMenus)">{{
                    subMenus.menuValue
                  }}</router-link>
                </li>
                <template v-else-if="subMenus.customSubmenuTwo">
                  <li class="submenu">
                    <a
                      href="javascript:void(0);"
                      @click="openSubmenuOne(subMenus)"
                      :class="{
                        subdrop: showSubRoute === subMenus,
                        active: route_array[1] === subMenus.active_link,
                      }"
                    >
                      {{ subMenus.menuValue }}
                      <span class="menu-arrow"></span>
                    </a>
                    <ul
                      :class="{
                        'd-block': showSubRoute === subMenus,
                        'd-none': showSubRoute !== subMenus,
                      }"
                    >
                      <li
                        v-for="subMenuTwo in subMenus.subMenusTwo"
                        :key="subMenuTwo.menuValue"
                      >
                        <router-link :to="subMenuTwo.route">{{
                          subMenuTwo.menuValue
                        }}</router-link>
                      </li>
                    </ul>
                  </li>
                </template>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </li>
    <li>
      <a href="javascript:void(0);"
        ><i class="ti ti-file-type-doc me-2"></i><span>Documentation</span></a
      >
    </li>
    <li>
      <a href="javascript:void(0);"
        ><i class="ti ti-arrow-capsule me-2"></i><span>Changelog v2.0.3</span></a
      >
    </li>
    <li class="submenu">
      <a
        :class="{ subdrop: multilevel[0] }"
        @click="multilevel[0] = !multilevel[0]"
        href="javascript:void(0);"
      >
        <i class="ti ti-brand-databricks me-2"></i>
        <span>Multi Level</span>
        <span class="menu-arrow"></span>
      </a>
      <ul :class="{ 'd-block': multilevel[0], 'd-none': !multilevel[0] }">
        <li class="submenu">
          <a
            :class="{ subdrop: multilevel[1] }"
            @click="multilevel[1] = !multilevel[1]"
            href="javascript:void(0);"
          >
            <span>Level 1</span>
            <span class="menu-arrow"></span>
          </a>
          <ul :class="{ 'd-block': multilevel[1], 'd-none': !multilevel[1] }">
            <li>
              <a href="javascript:void(0);"><span>Level 2</span></a>
            </li>
            <li class="submenu">
              <a
                :class="{ subdrop: multilevel[2] }"
                @click="multilevel[2] = !multilevel[2]"
                href="javascript:void(0);"
              >
                <span> Level 2</span>
                <span class="menu-arrow"></span>
              </a>
              <ul :class="{ 'd-block': multilevel[2], 'd-none': !multilevel[2] }">
                <li><a href="javascript:void(0);">Level 3</a></li>
                <li><a href="javascript:void(0);">Level 3</a></li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0);"><span>Level 2</span></a>
            </li>
          </ul>
        </li>
        <li>
          <a href="javascript:void(0);"><span>Level 1</span></a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import side_bar_data from "@/assets/json/sidebar-data.json";

export default {
  data() {
    return {
      side_bar_data: side_bar_data,
      openMenuItem: null,
      showSubRoute: null,
      route_array: [],
      multilevel: [false, false, false],
    };
  },
  methods: {
    expandSubMenus(menu) {
      this.side_bar_data.forEach((item) => {
        if (item.menu && Array.isArray(item.menu)) {
          item.menu.forEach((subMenu) => {
            if (subMenu !== menu) {
              subMenu.showSubRoute = false;
              subMenu.showSubRoute1 = false;
            }
          });
        }
      });
      menu.showSubRoute = !menu.showSubRoute;
    },
    openMenu(menu) {
      this.openMenuItem = this.openMenuItem === menu ? null : menu;
    },
    openSubmenuOne(subMenus) {
      this.showSubRoute = this.showSubRoute === subMenus ? null : subMenus;
    },
    getCurrentPath() {
      this.route_array = this.$route.path.split("/");
      return this.$route.path;
    },
  },
  computed: {
    currentPath() {
      return this.getCurrentPath();
    },
    isMenuActive() {
      return (menu) => {
        if (menu.menuValue === "Application") {
          return (
            this.$route.path.startsWith("/application") || // Check if current route starts with '/application'
            this.$route.path.startsWith("/call") ||
            this.$route.path === menu.active_link ||
            this.$route.path === menu.active_link1 ||
            this.$route.path === menu.active_link2
          );
        } else {
          return (
            this.$route.path === menu.route ||
            this.$route.path === menu.active_link ||
            this.$route.path === menu.active_link1 ||
            this.$route.path === menu.active_link2 ||
            this.$route.path === menu.active_link3 ||
            this.$route.path === menu.active_link4 ||
            this.$route.path === menu.active_link5 ||
            this.$route.path === menu.active_link6 ||
            this.$route.path === menu.active_link7 ||
            this.$route.path === menu.active_link8 ||
            this.$route.path === menu.active_link9 ||
            this.$route.path === menu.active_link10 ||
            this.$route.path === menu.active_link11
          );
        }
      };
    },
  },
};
</script>
