<template>
  <router-view></router-view>
  <div class="settings-footer">
    <main-footer />
  </div>
</template>

<style lang="scss" scoped>
.settings-footer {
  @media screen and (max-width: 992px) {
    padding: 0 16px;
  }
}
</style>
