<template>
  <a-table
    class="stripped table-hover"
    :columns="columns"
    :data-source="data"
    :row-selection="rowSelection"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'ID'">
        <div>
          <router-link
            to="/crm/deals-details"
            class="fs-12 fw-normal text-black"
            >{{ record.ID }}</router-link
          >
        </div>
      </template>
      <template v-else-if="column.key === 'Created_at'">
        <div class="fs-12 fw-normal text-black">{{ record.Created_at }}</div>
      </template>
      <template v-else-if="column.key === 'Deal_Type'">
        <td>
          <span :class="record.TypeClass">{{ record.Deal_Type }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'INN'">
        <div class="fs-12 fw-normal text-black">{{ record.INN }}</div>
      </template>
      <template v-else-if="column.key === 'Deal_Status'">
        <td class="d-flex w-100">
          <span :class="record.StatusClass">{{ record.Deal_Status }}</span>
        </td>
      </template>
      <template v-else-if="column.key === 'Date'">
        <div class="fs-12 fw-normal text-black">{{ record.Date }}</div>
      </template>
      <template v-else-if="column.key === 'Deal_comment'">
        <div class="fs-12 fw-normal badge badge-status badge-comment">
          {{ record.Deal_comment }}
        </div>
      </template>
      <template v-else-if="column.key === 'action'">
        <div class="dropdown table-action">
          <a
            href="javascript:;"
            class="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="fa fa-ellipsis-v"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right" style="">
            <a class="dropdown-item" href="javascript:;"
              ><i class="ti ti-bounce-right text-info"></i>Add Activity</a
            ><a class="dropdown-item edit-popup" href="javascript:;"
              ><i class="ti ti-edit text-blue"></i> Edit</a
            ><a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_deal"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            ><router-link class="dropdown-item" to="/crm/deals-details"
              ><i class="ti ti-eye text-blue-light"></i> Preview</router-link
            >
          </div>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
const data = [
  {
    key: "1",
    ID: "№ А67",
    Created_at: "25 Sep 2023",
    Deal_Type: "Лизинг",
    TypeClass: "badge badge-type",
    INN: "1234567890",
    Deal_Status: "Новая",
    StatusClass: "badge badge-status badge-new",
    Date: "25 Sep 2023",
    Deal_comment: "Высокий риск сделки",
  },
  {
    key: "2",
    ID: "№ А66",
    Created_at: "29 Sep 2023",
    Deal_Type: "Лизинг",
    TypeClass: "badge badge-type",
    INN: "1234567890",
    Deal_Status: "Новая",
    StatusClass: "badge badge-status badge-new",
    Date: "29 Sep 2023",
    Deal_comment: "",
  },
  {
    key: "3",
    ID: "№ А65",
    Created_at: "04 Oct 2023",
    Deal_Type: "Лизинг",
    TypeClass: "badge badge-type",
    INN: "1234567890",
    Deal_Status: "Принято",
    StatusClass: "badge badge-status badge-accepted",
    Date: "04 Oct 2023",
    Deal_comment: "Высокий риск сделки",
  },
  {
    key: "4",
    ID: "№ А64",
    Created_at: "15 Oct 2023",
    Deal_Type: "Лизинг",
    TypeClass: "badge badge-type",
    INN: "1234567890",
    Deal_Status: "Отклонено",
    StatusClass: "badge badge-status badge-rejected",
    Date: "15 Oct 2023",
    Deal_comment: "",
  },
  {
    key: "5",
    ID: "№ А63",
    Created_at: "27 Oct 2023",
    Deal_Type: "Лизинг",
    TypeClass: "badge badge-type",
    INN: "1234567890",
    Deal_Status: "Принято",
    StatusClass: "badge badge-status badge-accepted",
    Date: "27 Oct 2023",
    Deal_comment: "Высокий риск сделки",
  },
];

const columns = [
  {
    title: "ID",
    dataIndex: "ID",
    key: "ID",
    sorter: {
      compare: (a, b) => {
        a = a.ID.toLowerCase();
        b = b.ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата создания",
    dataIndex: "Created_at",
    key: "Created_at",
    sorter: {
      compare: (a, b) => {
        a = a.Created_at.toLowerCase();
        b = b.Created_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Тип сделки",
    dataIndex: "Deal_Type",
    key: "Deal_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Type.toLowerCase();
        b = b.Deal_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "ИНН",
    dataIndex: "INN",
    key: "INN",
    sorter: {
      compare: (a, b) => {
        a = a.INN.toLowerCase();
        b = b.INN.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Статус",
    dataIndex: "Deal_Status",
    key: "Deal_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_Status.toLowerCase();
        b = b.Deal_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Дата",
    dataIndex: "Date",
    key: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Комментарий",
    dataIndex: "Deal_comment",
    key: "Deal_comment",
    sorter: {
      compare: (a, b) => {
        a = a.Deal_comment?.trim().toLowerCase();
        b = b.Deal_comment?.trim().toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Действие",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
  data() {
    return {
      columns,
      data,
      rowSelection,
    };
  },
  mounted() {
    if (document.getElementsByClassName("edit-popup").length > 0) {
      var addPopups = document.getElementsByClassName("edit-popup");
      for (var i = 0; i < addPopups.length; i++) {
        addPopups[i].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.add("sidebar-popup");
          }
        });
      }

      var sidebarCloses = document.getElementsByClassName("sidebar-close1");
      for (var j = 0; j < sidebarCloses.length; j++) {
        sidebarCloses[j].addEventListener("click", () => {
          var togglePopup = document.getElementsByClassName("toggle-popup1")[0];
          if (togglePopup) {
            togglePopup.classList.remove("sidebar-popup");
          }
        });
      }
    }
  },
};
</script>
