// src/stores/auth.js
import { defineStore } from "pinia";
import apiClient from "@/axios";
import { useRouter } from "vue-router";
import { baseUrl } from "@/config";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: null,
    refreshToken: null,
    user: null, // Optional: user information
    router: useRouter(),
    activities: [],
  }),
  getters: {
    getUser: (state) => state.user,
    getActivities: (state) => state.activities,
  },
  actions: {
    async login(login, password) {
      const response = await apiClient.post(`${baseUrl}/auth/login`, {
        login,
        password,
      });

      const { access_token, refresh_token } = response.data;

      this.accessToken = access_token;
      this.refreshToken = refresh_token;

      // After successful login, fetch user information
      await this.fetchUser();

      this.router.push("/requests");
    },

    async refreshToken() {
      const response = await apiClient.post(`${baseUrl}/auth/refresh-token`);
      const { access_token } = response.data;

      this.accessToken = access_token;

      return access_token;
    },

    async logout() {
      try {
        await apiClient.get(`${baseUrl}/auth/logout`);
      } catch (error) {
        console.error("Error during logout:", error);
      } finally {
        localStorage.removeItem("auth");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        this.accessToken = null;
        this.refreshToken = null;
        this.user = null;
        this.activities = [];

        this.router.push("/");
      }
    },

    // Optional: Fetch user information
    async fetchUser() {
      if (!this.accessToken) return;
      try {
        const response = await apiClient.get(`${baseUrl}/auth/me`);
        this.user = response.data;
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    },

    async fetchActivities(accountId = this.user.id) {
      const response = await apiClient.get(
        `${baseUrl}/activity-histories/accounts/${accountId}`
      );
      this.activities = response.data;
    },

    async setUser(data) {
      try {
        const response = await apiClient.put(`${baseUrl}/accounts/`, data);
        this.user = response.data;
        return response.data;
      } catch (error) {
        console.error("Error setting user:", error);
      }
    },

    async setActivityViewed(activityIds) {
      const response = await apiClient.post(
        `${baseUrl}/activity-histories/viewed`,
        {
          activityIds: activityIds,
        }
      );
      await this.fetchActivities();
      return response;
    },
  },
  persist: {
    enabled: true, // Enable persistence
    strategies: [
      {
        storage: localStorage, // Use localStorage (default)
        paths: ["accessToken", "refreshToken", "user"], // Specify which state properties to persist
      },
    ],
  },
});
