<template>
  <div class="">
    <div class="main-wrapper">
      <layouts-header></layouts-header>
      <layouts-sidebar :is-admin="true"></layouts-sidebar>

      <div class="page-wrapper">
        <div class="page-analytics">
          <!-- Page Header -->
          <page-header :title="title" :breadcrumb="breadcrumb" />
          <!-- /Page Header -->

          <div class="page-content">
            <div class="row g-2">
              <div class="col-md-6 g-2">
                <div class="row g-2">
                  <div class="col-md-6">
                    <dashboard-stat title="Aктивные сделки" value="124" />
                  </div>
                  <div class="col-md-6">
                    <dashboard-stat
                      title="Общее количество  сделок"
                      value="124"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="card flex-fill mb-2">
                      <div class="card-header border-0 pb-0">
                        <div
                          class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
                        >
                          <h4>
                            <i class="ti ti-grip-vertical me-1"></i>Статистика
                            по заявкам
                          </h4>
                          <div class="dropdown">
                            <a
                              class="dropdown-toggle d-flex align-items-center gap-2"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_2088_5941)">
                                  <path
                                    d="M6 6.5H7V11"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.5 11H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 2.5H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2088_5941">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>За 7 Дней</p>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 7 Дней
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 14 Дней
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 30 Дней
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="donut-chart-wrapper">
                          <div class="donut-chart">
                            <apexchart
                              type="donut"
                              height="300"
                              :options="dealsStatusChart.donutc3"
                              :series="dealsStatusChart.series"
                            ></apexchart>
                          </div>
                          <div class="donut-chart">
                            <apexchart
                              type="donut"
                              height="300"
                              :options="dealsTypeChart.donutc3"
                              :series="dealsTypeChart.series"
                            ></apexchart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <won-chart></won-chart>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-md-12 d-flex">
                  <div class="card flex-fill mb-2">
                    <div class="card-header border-0 pb-0">
                      <div
                        class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
                      >
                        <h4>
                          <i class="ti ti-grip-vertical me-1"></i>История заявок
                        </h4>
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle d-flex align-items-center gap-2"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_2088_5941)">
                                <path
                                  d="M6 6.5H7V11"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5.5 11H8.5"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3.5 0.5V4.5"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.5 0.5V4.5"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M3.5 2.5H8.5"
                                  stroke="#000001"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2088_5941">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <p>За 7 Дней</p>
                          </a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a href="javascript:void(0);" class="dropdown-item">
                              За 7 Дней
                            </a>
                            <a href="javascript:void(0);" class="dropdown-item">
                              За 14 Дней
                            </a>
                            <a href="javascript:void(0);" class="dropdown-item">
                              За 30 Дней
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="table-responsive custom-table">
                          <deals-dashboard-table></deals-dashboard-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-flex">
                  <div class="card w-100">
                    <div class="card-header border-0 pb-0">
                      <div
                        class="d-flex align-items-center justify-content-between flex-wrap row-gap-3"
                      >
                        <h4>
                          <i class="ti ti-grip-vertical me-1"></i>Динамика
                          заявок
                        </h4>
                        <div
                          class="d-flex align-items-center flex-wrap row-gap-2"
                        >
                          <div class="dropdown">
                            <a
                              class="dropdown-toggle d-flex align-items-center gap-2"
                              data-bs-toggle="dropdown"
                              href="javascript:void(0);"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_2088_5941)">
                                  <path
                                    d="M6 6.5H7V11"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.5 11H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.5 0.5V4.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M3.5 2.5H8.5"
                                    stroke="#000001"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2088_5941">
                                    <rect width="14" height="14" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>За 12 мес</p>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 3 мес
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 6 мес
                              </a>
                              <a
                                href="javascript:void(0);"
                                class="dropdown-item"
                              >
                                За 12 мес
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div id="deals-year">
                        <apexchart
                          type="area"
                          height="273"
                          :options="dealsYear.chart"
                          :series="dealsYear.series"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-footer">
          <main-footer></main-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dealsYear, dealsStatusChart, dealsTypeChart } from "./data";

export default {
  data() {
    return {
      title: "Главная",
      breadcrumb: [
        {
          title: "Главная",
          link: "/admin/analytics",
        },
      ],
      dealsYear: dealsYear,
      dealsStatusChart: dealsStatusChart,
      dealsTypeChart: dealsTypeChart,
    };
  },
};
</script>

<style scoped lang="scss">
.donut-chart-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.donut-chart {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 244px;
    height: 244px;
    background-color: #f3f3f3;
    border-radius: 50%;
    z-index: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94px;
    height: 94px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }
}

.card-header {
  h4 {
    font-size: 16px;
  }
}

.card .card-body {
  @media screen and (max-width: 992px) {
    padding: 16px 0;
  }
}

.dropdown {
  p {
    font-size: 12px;
  }
}
</style>
