const DealsTable = [
  {
    id: 1,
    name: "№ А66",
    created_at: "2024-01-01",
    type: "Лизинг",
    typeclass: "badge badge-type",
    inn: "1234567890",
    status: "accepted",
    statusclass: "badge badge-status badge-accepted",
  },
  {
    id: 2,
    name: "№ А65",
    created_at: "2024-01-01",
    type: "Лизинг",
    typeclass: "badge badge-type",
    inn: "1234567890",
    status: "rejected",
    statusclass: "badge badge-status badge-rejected",
  },
  {
    id: 3,
    name: "№ А64",
    created_at: "2024-01-01",
    type: "Лизинг",
    typeclass: "badge badge-type",
    inn: "1234567890",
    status: "new",
    statusclass: "badge badge-status badge-new",
  },
];

export default DealsTable;
