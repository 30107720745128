<template>
  <!-- Add New Contact Stage -->
  <div class="modal fade" id="add_contact_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Contact Stage</h5>
          <button
            class="btn-close custom-btn-close border p-1 me-0 text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="col-form-label"
                >Contact Stage <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-0">
              <label class="col-form-label">Status</label>
              <div class="d-flex align-items-center">
                <div class="me-2">
                  <input
                    type="radio"
                    class="status-radio"
                    id="add-active"
                    name="status"
                    checked=""
                  />
                  <label for="add-active">Active</label>
                </div>
                <div>
                  <input
                    type="radio"
                    class="status-radio"
                    id="add-inactive"
                    name="status"
                  />
                  <label for="add-inactive">Inactive</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex align-items-center justify-content-end m-0">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add New Contact Stage -->

  <!-- Edit Contact Stage -->
  <div class="modal fade" id="edit_contact_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Contact Stage</h5>
          <button
            class="btn-close custom-btn-close border p-1 me-0 text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="col-form-label"
                >Contact Stage <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Contacted" />
            </div>
            <div class="mb-0">
              <label class="col-form-label">Status</label>
              <div class="d-flex flex-wrap radio-wrap">
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="edit-active"
                    name="status"
                    checked=""
                  />
                  <label for="edit-active">Active</label>
                </div>
                <div class="radio-btn">
                  <input
                    type="radio"
                    class="status-radio"
                    id="edit-inactive"
                    name="status"
                  />
                  <label for="edit-inactive">Inactive</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex align-items-center justify-content-end m-0">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Contact Stage -->

  <!-- Delete Contact Stage -->
  <div class="modal fade" id="delete_contact_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <div class="avatar avatar-xl bg-danger-light rounded-circle mb-3">
              <i class="ti ti-trash-x fs-36 text-danger"></i>
            </div>
            <h4 class="mb-2">Remove Contact Stage?</h4>
            <p class="mb-0">AAre you sure you want to remove it.</p>
            <div class="d-flex align-items-center justify-content-center mt-4">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <router-link
                to="/crm-settings/contact-stage"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Contact Stage -->
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/crm-settings/contact-stage");
    },
  },
};
</script>
