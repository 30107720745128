<template>
  <div class="chat chat-messages" id="middle">
    <div class="slimscroll">
      <perfect-scrollbar
        ref="ps"
        class="scroll-area-two"
        :settings="settings"
        @ps-scroll-y="scrollHandle"
      >
        <div class="chat-header">
          <div class="user-details">
            <div class="d-lg-none">
              <ul class="list-inline mt-2 me-2">
                <li class="list-inline-item">
                  <a
                    class="text-muted px-0 left_sides"
                    href="javascript:void(0);"
                    data-chat="open"
                    @click="emitOpenSidebar"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </a>
                </li>
              </ul>
            </div>
            <figure class="avatar ms-1">
              <img :src="chatData.avatar" alt="avatar" class="rounded-circle" />
            </figure>
            <div class="mt-1">
              <h5>{{ chatData.name }}</h5>
            </div>
          </div>
          <div class="chat-options">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a
                  href="javascript:void(0)"
                  @click="showChat"
                  class="btn btn-outline-light chat-search-btn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Search"
                >
                  <i class="bx bx-search"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- Chat Search -->
          <div class="chat-search" :class="{ 'visible-chat': chatVisible }">
            <form @submit.prevent="handleSearchSubmit">
              <span class="form-control-feedback">
                <i class="bx bx-search"></i>
              </span>
              <input
                type="text"
                name="chat-search"
                v-model="searchTerm"
                placeholder="Поиск по сообщениям"
                class="form-control"
              />
              <div
                class="close-btn-chat btn btn-outline-light no-bg"
                :style="{ border: 'none' }"
                @click="hideChat"
              >
                <i class="bx bx-x"></i>
              </div>
            </form>
          </div>
          <!-- /Chat Search -->
        </div>
        <div class="chat-body">
          <div class="messages" ref="messagesContainer">
            <template
              v-for="(group, groupIndex) in filteredMessages"
              :key="groupIndex"
            >
              <div class="chat-line">
                <span class="chat-date">{{ group.dateLabel }}</span>
              </div>
              <div
                class="chats"
                v-for="(message, msgIndex) in group.messages"
                :key="msgIndex"
                :class="{ 'chats-right': message.isOwn }"
              >
                <div v-if="!message.isOwn" class="chat-avatar">
                  <img
                    :src="chatData.avatar"
                    class="rounded-circle dreams_chat"
                    alt="avatar"
                  />
                </div>
                <div
                  class="chat-content"
                  :class="{
                    'message-highlight':
                      searchTerm &&
                      message.content
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                  }"
                >
                  <div
                    class="chat-profile-name"
                    :class="{ 'text-end': message.isOwn }"
                  >
                    <h6>
                      {{ message.sender }}
                      <span>{{ formatMessageTime(message.time) }}</span>
                    </h6>
                  </div>
                  <div class="message-content">
                    {{ message.content }}
                  </div>
                </div>
                <!-- User's avatar -->
                <div v-if="message.isOwn" class="chat-avatar">
                  <img
                    src="@/assets/img/profiles/avatar-default.svg"
                    class="rounded-circle dreams_chat"
                    alt="avatar"
                  />
                </div>
              </div>
            </template>
            <!-- No Messages Placeholder -->
            <div class="chat-line" v-if="filteredMessages.length === 0">
              <span class="chat-date">Сообщений не найдено.</span>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="chat-footer">
      <form @submit.prevent="sendMessage">
        <div class="d-flex w-100">
          <textarea
            v-model="newMessage"
            type="text"
            class="form-control chat_form"
            placeholder="Введите ваше сообщение..."
            @keydown.enter.exact.prevent="sendMessage"
          ></textarea>
        </div>
        <button class="btn btn-primary" type="submit">Отправить</button>
      </form>
    </div>
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    chatData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSidebar: false,
      chatVisible: false,
      settings: {
        suppressScrollX: true,
      },
      newMessage: "",
      searchTerm: "", // For search functionality
    };
  },
  computed: {
    groupedMessages() {
      if (!this.messages || this.messages.length === 0) return [];
      const groups = [];
      let lastDate = "";
      this.messages.forEach((message) => {
        const messageDate = new Date(message.time);
        const dateLabel = this.getMessageDateLabel(messageDate);
        if (dateLabel !== lastDate) {
          groups.push({
            dateLabel: dateLabel,
            messages: [],
          });
          lastDate = dateLabel;
        }
        groups[groups.length - 1].messages.push(message);
      });
      return groups;
    },
    filteredMessages() {
      if (!this.searchTerm.trim()) {
        return this.groupedMessages;
      }
      const term = this.searchTerm.toLowerCase();
      return this.groupedMessages
        .map((group) => {
          const filteredMsgs = group.messages.filter((msg) =>
            msg.content.toLowerCase().includes(term)
          );
          if (filteredMsgs.length > 0) {
            return {
              dateLabel: group.dateLabel,
              messages: filteredMsgs,
            };
          }
          return null;
        })
        .filter((group) => group !== null);
    },
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    emitOpenSidebar() {
      this.$emit("open-sidebar");
    },
    scrollHandle() {
      // Existing scroll handler logic
    },
    handleClick() {
      // Existing click handler logic
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    showChat() {
      this.chatVisible = true;
    },
    hideChat() {
      this.chatVisible = false;
    },
    sendMessage() {
      if (this.newMessage.trim() === "") return;
      // Создаем новый объект сообщения с точным временем
      const now = new Date();
      const isoTime = now.toISOString();
      const message = {
        sender: "You",
        time: isoTime,
        content: this.newMessage.trim(),
        isOwn: true,
      };
      // Эмитируем новое сообщение родительскому компоненту
      this.$emit("message-sent", message);
      this.newMessage = "";
      this.$nextTick(() => {
        this.scrollToBottom(); // Прокручиваем к нижней части после отправки сообщения
      });
    },
    getMessageDateLabel(date) {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      // Reset the time components for accurate comparison
      today.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (date.getTime() === today.getTime()) {
        return "Сегодня";
      } else if (date.getTime() === yesterday.getTime()) {
        return "Вчера";
      } else {
        return date.toLocaleDateString("ru-RU", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    },
    formatMessageTime(rawTime) {
      const date = new Date(rawTime);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    scrollToBottom() {
      const ps = this.$refs.ps; // Доступ к PerfectScrollbar через ref
      if (ps && ps.$el) {
        // Используем встроенный метод update для обновления скроллбара
        ps.update();

        // Получаем внутренний контейнер с прокруткой
        const container = ps.$el.querySelector(".ps__rail-y");

        if (container) {
          // Прокручиваем к нижней части
          container.parentElement.scrollTop =
            container.parentElement.scrollHeight;
        }
      }
    },
    handleSearchSubmit() {
      // After searching, scroll to the first matched message
      this.$nextTick(() => {
        const firstMatch = this.$el.querySelector(".message-highlight");
        if (firstMatch) {
          firstMatch.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>
<style scoped>
.chats {
  display: flex;
  margin-bottom: 15px;
}
.chats .chat-avatar {
  margin-right: 10px;
}
.chats-right {
  flex-direction: row-reverse;
}
.chats-right .chat-avatar {
  margin-left: 10px;
  margin-right: 0;
}
.chat-content {
  max-width: 70%;
  padding: 0px;
  border-radius: 5px;
}
.chats-right .chat-content {
  text-align: right;
}
.chat-profile-name h6 {
  margin: 0;
  font-size: 14px;
}
.chat-profile-name span {
  font-size: 12px;
  color: #888;
  margin-left: 5px;
}
.message-content {
  margin-top: 5px;
}
.chat-line {
  text-align: center;
  margin: 15px 0;
  color: #888;
}
.avatar img {
  width: 40px;
  height: 40px;
}
.chat-avatar img {
  width: 40px;
  height: 40px;
}
.message-highlight {
  background-color: #ffffcc;
}
.messages {
  scroll-behavior: smooth;
}
</style>
