<template>
    <div class="col-xxl-3 col-xl-4 col-md-6" v-for="item in ContactGrid" :key="item.id">
        <div class="card border">
            <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex align-items-center">
                        <router-link to="/crm/contact-details" class="avatar avatar-md flex-shrink-0 me-2">
                            <img :src="require(`@/assets/img/profiles/${item.customer_image}`)" alt="img">
                        </router-link>
                        <div>
                            <h6><router-link to="/crm/contact-details" class="fw-medium">{{item.customer_name}}</router-link></h6>
                            <p class="text-default">{{item.customer_no}}</p>
                        </div>
                    </div>
                    <div class="dropdown table-action">
                        <a href="#" class="action-icon " data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvas_edit"><i class="ti ti-edit text-blue"></i> Edit</a>
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete_contact"><i class="ti ti-trash text-danger"></i> Delete</a>
                            <router-link class="dropdown-item" to="/crm/contact-details"><i class="ti ti-eye text-blue-light"></i> Preview</router-link>
                        </div>
                    </div>
                </div>
                <div class="d-block">
                    <div class="d-flex flex-column mb-3">
                        <p class="text-default d-inline-flex align-items-center mb-2"><i class="ti ti-mail text-dark me-1"></i>{{item.email}}</p>
                        <p class="text-default d-inline-flex align-items-center mb-2"><i class="ti ti-phone text-dark me-1"></i>{{item.phone}}</p>
                        <p class="text-default d-inline-flex align-items-center"><i class="ti ti-map-pin-pin text-dark me-1"></i>{{item.location}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <span class="badge badge-tag badge-success-light me-2">Collab</span>
                        <span class="badge badge-tag badge-warning-light">Rated</span>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-3 pt-3 border-top">
                    <div class="d-flex align-items-center grid-social-links">
                        <a href="#" class="avatar avatar-xs text-dark rounded-circle me-1"><i class="ti ti-mail fs-14"></i></a>
                        <a href="#" class="avatar avatar-xs text-dark rounded-circle me-1"><i class="ti ti-phone-check fs-14"></i></a>
                        <a href="#" class="avatar avatar-xs text-dark rounded-circle me-1"><i class="ti ti-message-circle-share fs-14"></i></a>
                        <a href="#" class="avatar avatar-xs text-dark rounded-circle me-1"><i class="ti ti-brand-skype fs-14"></i></a>
                        <a href="#" class="avatar avatar-xs text-dark rounded-circle"><i class="ti ti-brand-facebook fs-14"></i></a>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="set-star text-default me-2">
                            <i class="fa fa-star filled me-1"></i>{{item.rating}}
                        </div>
                        <a href="javascript:void(0);" class="avatar avatar-md" data-bs-toggle="tooltip" data-bs-original-title="Mervin" data-bs-placement="top">
                            <img :src="require(`@/assets/img/profiles/${item.owner_image}`)" alt="img">
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import ContactGrid from '@/assets/json/contacts.json'
export default {
    data() {
        return {
            ContactGrid: ContactGrid,
        }
    }
}
</script>