<template>
  <div class="sidebar-contact">
    <div class="toggle-theme" @click="toggleTheme">
      <i class="fa fa-cog fa-w-16 fa-spin"></i>
    </div>
  </div>
  <div class="sidebar-themesettings" :class="{ open: isThemeSettingsOpen }">
    <div class="themesettings-header">
      <h4>Theme Customizer</h4>
      <a href="javascript:;" id="theme-settings" @click.prevent="closeThemeSettings"
        ><i class="ti ti-x"></i
      ></a>
    </div>
    <div class="themesettings-inner">
      <div class="themesettings-content">
        <h6>Layout</h6>
        <div class="row">
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="theme" id="lightTheme" value="light" checked />
              <label for="lightTheme">
                <img src="@/assets/img/theme/theme-01.svg" alt="img" />
                <span class="w-100">
                  <span>Light</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="theme" id="darkTheme" value="dark" />
              <label for="darkTheme">
                <img src="@/assets/img/theme/theme-02.svg" alt="img" />
                <span class="w-100">
                  <span>Dark</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="themesettings-content">
        <h6>Colors</h6>
        <div class="row">
          <div class="col-lg-12">
            <div class="theme-colorsset">
              <ul>
                <li>
                  <div class="input-themeselects">
                    <input type="radio" name="color" id="redColor" value="red" checked />
                    <label for="redColor" class="red-clr"></label>
                  </div>
                </li>
                <li>
                  <div class="input-themeselects">
                    <input type="radio" name="color" id="yellowColor" value="yellow" />
                    <label for="yellowColor" class="yellow-clr"></label>
                  </div>
                </li>
                <li>
                  <div class="input-themeselects">
                    <input type="radio" name="color" id="blueColor" value="blue" />
                    <label for="blueColor" class="blue-clr"></label>
                  </div>
                </li>
                <li>
                  <div class="input-themeselects">
                    <input type="radio" name="color" id="greenColor" value="green" />
                    <label for="greenColor" class="green-clr"></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="themesettings-content">
        <h6>Sidebar</h6>
        <div class="row">
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input
                type="radio"
                name="sidebar"
                id="lightSidebar"
                value="light"
                checked
              />
              <label for="lightSidebar">
                <img src="@/assets/img/theme/theme-03.svg" alt="img" />
                <span class="w-100">
                  <span>Light</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="darkSidebar" value="dark" />
              <label for="darkSidebar">
                <img src="@/assets/img/theme/theme-04.svg" alt="img" />
                <span class="w-100">
                  <span>Dark</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="blueSidebar" value="blue" />
              <label for="blueSidebar">
                <img src="@/assets/img/theme/theme-05.svg" alt="img" />
                <span class="w-100">
                  <span>Blue</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="greenSidebar" value="green" />
              <label for="greenSidebar">
                <img src="@/assets/img/theme/theme-06.svg" alt="img" />
                <span class="w-100">
                  <span>Green</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="themesettings-content m-0 border-0">
        <h6>Sidebar Background</h6>
        <div class="row">
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="sidebarBg1" value="sidebarbg1" />
              <label for="sidebarBg1">
                <img src="@/assets/img/theme/theme-07.svg" alt="img" />
                <span class="w-100">
                  <span>Bg-1</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="sidebarBg2" value="sidebarbg2" />
              <label for="sidebarBg2">
                <img src="@/assets/img/theme/theme-08.svg" alt="img" />
                <span class="w-100">
                  <span>Bg-2</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="sidebarBg3" value="sidebarbg3" />
              <label for="sidebarBg3">
                <img src="@/assets/img/theme/theme-09.svg" alt="img" />
                <span class="w-100">
                  <span>Bg-3</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-themeselect">
              <input type="radio" name="sidebar" id="sidebarBg4" value="sidebarbg4" />
              <label for="sidebarBg4">
                <img src="@/assets/img/theme/theme-10.svg" alt="img" />
                <span class="w-100">
                  <span>Bg-4</span>
                  <span class="checkboxs-theme"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="themesettings-footer">
      <ul>
        <li>
          <a
            href="javascript:;"
            @click.prevent="closeThemeSettings"
            class="btn btn-cancel close-theme"
            >Cancel</a
          >
        </li>
        <li>
          <a href="javascript:;" id="resetbutton" class="btn btn-reset">Reset</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="sidebar-themeoverlay" :class="{ open: isThemeSettingsOpen }"></div>
</template>

<script>
export default {
  data() {
    return {
      isThemeSettingsOpen: false,
    };
  },
  methods: {
    toggleTheme() {
      this.isThemeSettingsOpen = !this.isThemeSettingsOpen;
    },
    closeThemeSettings() {
      this.isThemeSettingsOpen = false;
    },
    setThemeAndSidebarTheme(theme, sidebarTheme, color, sidebarBg) {
      document.documentElement.setAttribute("data-theme", theme);
      document.documentElement.setAttribute("data-sidebar", sidebarTheme);
      document.documentElement.setAttribute("data-color", color);
      localStorage.setItem("theme", theme);
      localStorage.setItem("sidebarTheme", sidebarTheme);
      localStorage.setItem("color", color);
      localStorage.setItem("sidebarBg", sidebarBg);
    },
    handleInputChange() {
      const theme = document.querySelector('input[name="theme"]:checked')
        ? document.querySelector('input[name="theme"]:checked').value
        : "light";
      const sidebarTheme = document.querySelector('input[name="sidebar"]:checked')
        ? document.querySelector('input[name="sidebar"]:checked').value
        : "light";
      const color = document.querySelector('input[name="color"]:checked')
        ? document.querySelector('input[name="color"]:checked').value
        : "red";
      const sidebarBg = document.querySelector('input[name="sidebarbg"]:checked')
        ? document.querySelector('input[name="sidebarbg"]:checked').value
        : "sidebarbg1";
      this.setThemeAndSidebarTheme(theme, sidebarTheme, color, sidebarBg);
    },
    resetThemeAndSidebarThemeAndColorAndBg() {
      this.setThemeAndSidebarTheme("light", "light", "red", "sidebarbg1");
      const lightThemeRadio = document.getElementById("lightTheme");
      const lightSidebarRadio = document.getElementById("lightSidebar");
      const redColorRadio = document.getElementById("redColor");
      if (lightThemeRadio) {
        lightThemeRadio.checked = true;
      }
      if (lightSidebarRadio) {
        lightSidebarRadio.checked = true;
      }
      if (redColorRadio) {
        redColorRadio.checked = true;
      }
    },
  },
  mounted() {
    const savedTheme = localStorage.getItem("theme") || "light";
    const savedSidebarTheme = localStorage.getItem("sidebarTheme") || "light";
    const savedColor = localStorage.getItem("color") || "red";
    this.setThemeAndSidebarTheme(savedTheme, savedSidebarTheme, savedColor);
    document.getElementById(`${savedTheme}Theme`).checked = true;
    document.getElementById(`${savedColor}Color`).checked = true;

    const themeRadios = document.querySelectorAll('input[name="theme"]');
    const sidebarRadios = document.querySelectorAll('input[name="sidebar"]');
    const colorRadios = document.querySelectorAll('input[name="color"]');
    const sidebarBgRadios = document.querySelectorAll('input[name="sidebarbg"]');
    const resetbutton = document.getElementById("resetbutton");

    themeRadios.forEach((radio) =>
      radio.addEventListener("change", this.handleInputChange)
    );
    sidebarRadios.forEach((radio) =>
      radio.addEventListener("change", this.handleInputChange)
    );
    colorRadios.forEach((radio) =>
      radio.addEventListener("change", this.handleInputChange)
    );
    sidebarBgRadios.forEach((radio) =>
      radio.addEventListener("change", this.handleInputChange)
    );
    resetbutton.addEventListener("click", this.resetThemeAndSidebarThemeAndColorAndBg);
  },
};
</script>
