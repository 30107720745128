<template>
  <!-- Add New Pipeline -->
  <div
    class="offcanvas offcanvas-end offcanvas-large"
    tabindex="-1"
    id="offcanvas_pipeline"
  >
    <div class="offcanvas-header border-bottom">
      <h4>Add New Pipeline</h4>
      <button
        type="button"
        class="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <form @submit.prevent="submitForm">
        <div>
          <div class="mb-3">
            <label class="col-form-label"
              >Pipeline Name <span class="text-danger">*</span></label
            >
            <input class="form-control" type="text" />
          </div>
          <div class="mb-3">
            <div class="pipe-title d-flex align-items-center justify-content-between">
              <h5 class="form-title">Pipeline Stages</h5>
              <a
                href="#"
                class="add-stage"
                data-bs-toggle="modal"
                data-bs-target="#add_stage"
                ><i class="ti ti-square-rounded-plus"></i>Add New</a
              >
            </div>
            <div class="pipeline-listing">
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Inpipeline</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Follow Up</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Schedule Service</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <h5 class="form-title">Access</h5>
            <div class="d-flex flex-wrap access-item nav">
              <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#all">
                <input
                  type="radio"
                  class="status-radio"
                  id="all"
                  name="status"
                  checked=""
                />
                <label for="all">All</label>
              </div>
              <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#select-person">
                <input type="radio" class="status-radio" id="select" name="status" />
                <label for="select">Select Person</label>
              </div>
            </div>
            <div class="tab-content">
              <div class="tab-pane fade" id="select-person">
                <div class="access-wrapper">
                  <div class="access-view">
                    <div class="access-img">
                      <img src="@/assets/img/profiles/avatar-21.jpg" alt="Image" />Vaughan
                    </div>
                    <a href="#">Remove</a>
                  </div>
                  <div class="access-view">
                    <div class="access-img">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="Image" />Jessica
                    </div>
                    <a href="#">Remove</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button type="button" data-bs-dismiss="offcanvas" class="btn btn-light me-2">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="delete_pipeline"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- /Add New Pipeline -->

  <!-- Edit Pipeline -->
  <div class="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_edit">
    <div class="offcanvas-header border-bottom">
      <h4>Edit Pipeline</h4>
      <button
        type="button"
        class="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <form @submit.prevent="submitForm">
        <div>
          <div class="mb-3">
            <label class="col-form-label"
              >Pipeline Name <span class="text-danger">*</span></label
            >
            <input class="form-control" type="text" value="Inpipeline" />
          </div>
          <div class="mb-3">
            <div class="pipe-title d-flex align-items-center justify-content-between">
              <h5 class="form-title">Pipeline Stages</h5>
              <a
                href="#"
                class="add-stage"
                data-bs-toggle="modal"
                data-bs-target="#add_stage"
                ><i class="ti ti-square-rounded-plus"></i>Add New</a
              >
            </div>
            <div class="pipeline-listing">
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Inpipeline</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Follow Up</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
              <div class="pipeline-item">
                <p><i class="ti ti-grip-vertical"></i> Schedule Service</p>
                <div class="action-pipeline">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"
                    ><i class="ti ti-edit text-blue"></i>Edit</a
                  >
                  <a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"
                    ><i class="ti ti-trash text-danger"></i>Delete</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <h5 class="form-title">Access</h5>
            <div class="d-flex flex-wrap access-item nav">
              <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#all">
                <input
                  type="radio"
                  class="status-radio"
                  id="all"
                  name="status"
                  checked=""
                />
                <label for="all">All</label>
              </div>
              <div class="radio-btn" data-bs-toggle="tab" data-bs-target="#select-person">
                <input type="radio" class="status-radio" id="select" name="status" />
                <label for="select">Select Person</label>
              </div>
            </div>
            <div class="tab-content mb-3">
              <div class="tab-pane fade" id="select-person">
                <div class="access-wrapper">
                  <div class="access-view">
                    <div class="access-img">
                      <img src="@/assets/img/profiles/avatar-21.jpg" alt="Image" />Vaughan
                    </div>
                    <a href="#">Remove</a>
                  </div>
                  <div class="access-view">
                    <div class="access-img">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="Image" />Jessica
                    </div>
                    <a href="#">Remove</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button type="button" data-bs-dismiss="offcanvas" class="btn btn-light me-2">
            Cancel
          </button>
          <button type="button" class="btn btn-primary">Save Changes</button>
        </div>
      </form>
    </div>
  </div>
  <!-- /Edit Pipeline -->

  <!-- Delete Pipeline -->
  <div class="modal fade" id="delete_pipeline" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <div class="avatar avatar-xl bg-danger-light rounded-circle mb-3">
              <i class="ti ti-trash-x fs-36 text-danger"></i>
            </div>
            <h4 class="mb-2">Remove Pipeline?</h4>
            <p class="mb-0">
              Are you sure you want to remove <br />
              pipeline you selected.
            </p>
            <div class="d-flex align-items-center justify-content-center mt-4">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <router-link
                to="/crm/pipeline-list"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Stage -->

  <!-- Delete Stage -->
  <div class="modal fade" id="delete_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <div class="avatar avatar-xl bg-danger-light rounded-circle mb-3">
              <i class="ti ti-trash-x fs-36 text-danger"></i>
            </div>
            <h4 class="mb-2">Remove Stage?</h4>
            <p class="mb-0">
              Are you sure you want to remove <br />
              stage you selected.
            </p>
            <div class="d-flex align-items-center justify-content-center mt-4">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <router-link
                to="/crm/pipeline-list"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                >Yes, Delete it</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Stage -->

  <!-- Add New View -->
  <div class="modal custom-modal fade" id="save_view" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New View</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label class="col-form-label">View Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New View -->

  <!-- Add New Stage -->
  <div class="modal custom-modal fade" id="add_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Stage</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label class="col-form-label">Stage Name *</label>
              <input type="text" class="form-control" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Stage -->

  <!-- Edit Stage -->
  <div class="modal custom-modal fade" id="edit_stage" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Stage</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label class="col-form-label">Stage Name *</label>
              <input type="text" class="form-control" value="Inpipeline" />
            </div>
            <div class="modal-btn text-end">
              <a href="#" class="btn btn-light" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-danger">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Stage -->
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/crm/pipeline-list");
    },
  },
};
</script>
