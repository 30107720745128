<template>
  <div class="card">
    <div class="card-body pb-0 pt-2">
      <ul class="nav nav-tabs nav-tabs-bottom">
        <li class="nav-item me-3">
          <router-link
            v-bind:class="{
              'nav-link px-0': true,
              active:
                currentPath.includes('security') ||
                currentPath.includes('notifications') ||
                currentPath.includes('connected-apps'),
            }"
            to="/settings/profile"
          >
            <i class="ti ti-settings-cog me-2"></i> Настройки аккаунта
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
};
</script>
