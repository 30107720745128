import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";

export const useRequestsStore = defineStore("requests", {
  state: () => ({
    requests: [],
    acceptedDealsCount: 0,
    finishedDealsCount: 0,
  }),
  getters: {
    getRequests: (state) => state.requests,
    getAcceptedDealsCount: (state) => state.acceptedDealsCount,
    getFinishedDealsCount: (state) => state.finishedDealsCount,
  },
  actions: {
    async fetchRequests() {
      const response = await apiClient.get(`${baseUrl}/bids`);
      this.requests = response.data;
      this.acceptedDealsCount = response.data.filter(
        (item) => item.status === "accepted"
      ).length;
      this.finishedDealsCount = response.data.filter(
        (item) => item.status === "finished"
      ).length;
    },
    async rejectRequest(data) {
      const response = await apiClient.post(`${baseUrl}/bids/reject`, {
        ...data,
      });
      return response;
    },
    async acceptRequest(data) {
      const response = await apiClient.post(`${baseUrl}/bids/accept`, {
        ...data,
      });

      return response;
    },
    async finishRequest(data) {
      const response = await apiClient.post(
        `${baseUrl}/deals/${data.leadId}/status/finished`,
        {
          ...data,
        }
      );
      return response;
    },
  },
});
