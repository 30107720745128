<template>
  <div class="main-chat-blk">
    <div class="main-wrapper">
      <layouts-header></layouts-header>
      <layouts-sidebar></layouts-sidebar>

      <div class="page-wrapper chat-page-wrapper">
        <div class="content flex-column">
          <!-- Page Header -->
          <page-header :title="title" :breadcrumb="breadcrumb" />
          <!-- /Page Header -->

          <!-- Sidebar Group -->
          <div class="py-4 px-3 bg-white rounded-2">
            <div
              class="d-flex"
              :style="'border: 1px solid #D5D5D5; border-radius: 5px; overflow: hidden'"
            >
              <div
                class="sidebar-group left-sidebar chat_sidebar"
                ref="chatSidebar"
              >
                <!-- Chats Sidebar -->
                <div
                  id="chats"
                  class="left-sidebar-wrap sidebar active slimscroll"
                >
                  <perfect-scrollbar
                    class="scroll-area-one"
                    :settings="settings"
                    @ps-scroll-y="scrollHanle"
                  >
                    <div class="slimscroll-active-sidebar">
                      <!-- Left Chat Title -->
                      <div
                        class="left-chat-title all-chats d-flex justify-content-between align-items-center"
                      >
                        <div class="setting-title-head">
                          <h4>Группы</h4>
                        </div>
                        <div class="add-section">
                          <ul>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="user-chat-search-btn"
                              >
                                <i class="bx bx-search"></i>
                              </a>
                            </li>
                          </ul>
                          <!-- Chat Search -->
                          <div class="user-chat-search">
                            <form>
                              <span class="form-control-feedback">
                                <i class="bx bx-search"></i>
                              </span>
                              <input
                                type="text"
                                name="chat-search"
                                placeholder="Search"
                                class="form-control"
                              />
                              <div class="user-close-btn-chat">
                                <span class="material-icons">close</span>
                              </div>
                            </form>
                          </div>
                          <!-- /Chat Search -->
                        </div>
                      </div>
                      <!-- /Left Chat Title -->

                      <!-- Chat List -->
                      <div class="sidebar-body chat-body" id="chatsidebar">
                        <ul class="user-list">
                          <li
                            v-for="chat in chats"
                            :key="chat.id"
                            :class="[
                              'user-list-item',
                              {
                                active:
                                  selectedChat && selectedChat.id === chat.id,
                              },
                            ]"
                            @click="selectChat(chat)"
                          >
                            <a href="javascript:void(0);">
                              <div class="avatar">
                                <img
                                  :src="chat.avatar"
                                  class="rounded-circle"
                                  :alt="chat.name"
                                />
                              </div>
                              <div class="users-list-body">
                                <div>
                                  <h5>{{ chat.name }}</h5>
                                  <p>{{ chat.lastMessage }}</p>
                                </div>
                                <div class="last-chat-time">
                                  <small class="text-muted">{{
                                    formatTime(chat.time)
                                  }}</small>
                                  <div
                                    v-if="chat.unread"
                                    class="new-message-count"
                                  >
                                    {{ chat.unread }}
                                  </div>
                                  <div v-else-if="chat.pinned" class="chat-pin">
                                    <i class="bx bx-check-double"></i>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- /Chat List -->
                    </div>
                  </perfect-scrollbar>
                </div>
                <!-- /Chats Sidebar -->
              </div>
              <!-- /Sidebar Group -->

              <!-- Chat Content -->
              <div ref="chatContent" class="chat-content-wrapper">
                <chat-content
                  :chat-data="selectedChat"
                  :messages="[]"
                  @message-sent="addMessage(selectedChat.id, $event)"
                  @open-sidebar="openSidebar"
                ></chat-content>
              </div>
              <!-- /Chat Content -->
            </div>
          </div>

          <main-footer></main-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { ref } from "vue";
// Import avatars
import avatar2 from "@/assets/img/profiles/avatar-02.jpg";

export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      title: "Чат",
      breadcrumb: [
        {
          title: "Чат",
          link: "/chat",
        },
      ],
      chats: [
        {
          id: 1,
          name: "Сделка № А64",
          lastMessage: "Have you called them?",
          time: "2024-11-01T19:05:00", // ISO format
          avatar: avatar2,
          unread: 11,
        },
        {
          id: 2,
          name: "Общий чат: ВТБ Лизинг х Автопартнер",
          lastMessage: "Заявка на лизинг была отправлена",
          time: "2024-10-31T10:30:00", // ISO format
          avatar: avatar2,
          pinned: true,
        },
        {
          id: 3,
          name: "Сделка № А12",
          lastMessage: "Заявка на лизинг была отправлена",
          time: "2024-10-31T10:23:00", // ISO format
          avatar: avatar2,
          pinned: true,
        },
        {
          id: 4,
          name: "Сделка № А62",
          lastMessage: "Заявка на лизинг была отправлена",
          time: "2024-10-28T10:30:00", // ISO format
          avatar: avatar2,
          pinned: true,
        },
      ],
      // Mock messages for each chat with raw timestamps
      messages: {
        1: [
          {
            sender: "Автопартнер",
            time: "2024-10-29T18:41:00", // ISO format
            content:
              "Hello Alex! Thank you for the beautiful web design ahead schedule.",
            isOwn: false,
          },
          {
            sender: "You",
            time: "2024-10-29T19:00:00", // ISO format
            content: "Thank you! Glad you like it.",
            isOwn: true,
          },
          {
            sender: "Автопартнер",
            time: "2024-11-01T19:05:00", // ISO format
            content: "Looking forward to the next steps.",
            isOwn: false,
          },
        ],
        2: [
          {
            sender: "Автопартнер",
            time: "2024-10-30T08:16:00", // ISO format
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            isOwn: false,
          },
          {
            sender: "ВТБ Лизинг",
            time: "2024-10-31T20:16:00", // ISO format
            content: "Thank you for your support.",
            isOwn: true,
          },
        ],
        3: [
          {
            sender: "Сделка № А12",
            time: "2024-10-31T10:23:00", // ISO format
            content: "Заявка на лизинг была отправлена.",
            isOwn: false,
          },
          {
            sender: "You",
            time: "2024-10-31T10:25:00", // ISO format
            content: "Получено, приступаю к обработке.",
            isOwn: true,
          },
        ],
        4: [
          {
            sender: "Сделка № А62",
            time: "2024-10-28T10:30:00", // ISO format
            content: "Заявка на лизинг была отправлена.",
            isOwn: false,
          },
          {
            sender: "You",
            time: "2024-10-28T10:35:00", // ISO format
            content: "Спасибо за информацию.",
            isOwn: true,
          },
        ],
      },
      selectedChat: null, // Will hold the currently selected chat
    };
  },
  setup() {
    const chatSidebar = ref(null);
    const chatContent = ref(null);
    return {
      chatSidebar,
      chatContent,
    };
  },
  created() {
    // Optionally set the first chat as selected by default
    if (this.chats.length > 0) {
      this.selectedChat = this.chats[0];
    }
  },
  methods: {
    scrollHanle() {
      // Existing scroll handler logic
    },
    selectChat(chat) {
      this.selectedChat = chat;
      this.closeSidebar();
    },
    closeSidebar() {
      if (window.innerWidth < 992) {
        this.chatSidebar.style.display = "none";
        this.chatContent.style.display = "block";
      }
    },
    openSidebar() {
      if (window.innerWidth < 992) {
        this.chatSidebar.style.display = "flex";
        this.chatContent.style.display = "none";
      }
    },
    addMessage(chatId, message) {
      if (this.messages[chatId]) {
        this.messages[chatId].push(message);
      } else {
        this.$set(this.messages, chatId, [message]);
      }

      // Update lastMessage and time for the chat
      const chat = this.chats.find((c) => c.id === chatId);
      if (chat) {
        chat.lastMessage = message.content;
        // Update time with ISO format
        const messageDate = new Date(message.time);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (
          messageDate.getFullYear() === today.getFullYear() &&
          messageDate.getMonth() === today.getMonth() &&
          messageDate.getDate() === today.getDate()
        ) {
          chat.time = "Сегодня";
        } else if (
          messageDate.getFullYear() === yesterday.getFullYear() &&
          messageDate.getMonth() === yesterday.getMonth() &&
          messageDate.getDate() === yesterday.getDate()
        ) {
          chat.time = "Вчера";
        } else {
          chat.time = messageDate.toLocaleDateString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
      }
    },
    // Format time for display in chat list
    formatTime(rawTime) {
      const date = new Date(rawTime);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      ) {
        // Return time in HH:MM format
        return date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else if (
        date.getFullYear() === yesterday.getFullYear() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getDate() === yesterday.getDate()
      ) {
        return "Вчера";
      } else {
        return date.toLocaleDateString("ru-RU", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    },
  },
};
</script>

<style scoped>
.user-list-item.active {
  background-color: #f0f0f0;
}
</style>
