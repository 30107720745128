<template>
  <div class="login-page">
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">
          <div class="login-form">
            <form @submit.prevent="submitForm" class="flex-fill">
              <div class="mx-auto mw-450">
                <div class="text-center mb-4 login-logo">
                  <img
                    src="@/assets/img/logo.svg"
                    class="img-fluid"
                    alt="Logo"
                  />
                </div>
                <div class="mb-4">
                  <h4 class="mb-2 fs-20 login-title">Забыли пароль?</h4>
                  <p class="login-text">
                    Если вы забыли пароль, то мы отправим вам инструкции для
                    сброса пароля.
                  </p>
                </div>
                <div class="mb-3">
                  <label class="col-form-label">Email</label>
                  <div class="position-relative">
                    <span class="input-icon-addon">
                      <i class="ti ti-mail"></i>
                    </span>
                    <input type="text" value="" class="form-control" />
                  </div>
                </div>
                <div class="mb-3">
                  <button type="submit" class="btn btn-primary w-100 login-btn">
                    Отправить
                  </button>
                </div>
                <div class="mb-3 text-center">
                  <p class="login-link">
                    Вернуться ко
                    <router-link to="/" class="link-hover"> входу</router-link>
                  </p>
                </div>
              </div>
            </form>
            <div
              class="login-contacts text-center d-flex justify-content-center gap-3"
            >
              <p class="fw-medium">
                E: <a href="mailto:presales@gcap.ru">presales@gcap.ru</a>
              </p>
              <p>Т: <a href="tel:+78047006400">+7 (804) 700-64-00</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <main-footer />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/");
    },
  },
};
</script>
