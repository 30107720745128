<template>
  <!-- Add Printer -->
  <div class="modal fade" id="add_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Printer</h5>
          <button
            class="btn-close custom-btn-close border p-1 me-0 text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label"
                >Printer Company <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Printer Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Connection Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >IP Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-0">
              <label class="form-label">Port <span class="text-danger">*</span></label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex align-items-center">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Currency -->

  <!-- Edit Printer -->
  <div class="modal fade" id="edit_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Printer</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label"
                >Printer Company <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Hp" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Printer Name <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Hp printer" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Connection Type <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="Network" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >IP Address <span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" value="198.162.0.1" />
            </div>
            <div class="mb-0">
              <label class="form-label">Port <span class="text-danger">*</span></label>
              <input type="text" class="form-control" value="900" />
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex align-items-center">
              <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Printer -->

  <!-- Delete Printer -->
  <div class="modal fade" id="delete_printer" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="text-center">
              <div class="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                <i class="ti ti-trash-x fs-36 text-danger"></i>
              </div>
              <h4 class="mb-2">Remove Printer?</h4>
              <p class="mb-0">Are you sure you want to remove it.</p>
              <div class="d-flex align-items-center justify-content-center mt-4">
                <a href="#" class="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-danger" data-bs-dismiss="modal">
                  Yes, Delete it
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Printer -->
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/app-settings/printers");
    },
  },
};
</script>
