<template>
  <layouts-header></layouts-header>
  <layouts-sidebar></layouts-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <!-- Page Header -->
          <page-header :title="title" />
          <!-- /Page Header -->

          <div class="card">
            <!-- Search -->
            <div class="card-header">
              <!-- Search -->
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <div class="icon-form mb-3 mb-sm-0 activities-search">
                    <span class="form-icon"><i class="ti ti-search"></i></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Поиск уведомлений"
                      v-model="searchQuery"
                    />
                  </div>
                </div>
                <div class="col-sm-8"></div>
              </div>
              <!-- /Search -->
            </div>
            <!-- /Search -->
            <div class="card-body">
              <!-- Activity List -->
              <div class="table-responsive custom-table">
                <activities-table :search-query="searchQuery"></activities-table>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="datatable-length"></div>
                </div>
                <div class="col-md-6">
                  <div class="datatable-paginate"></div>
                </div>
              </div>
              <!-- /Activity List -->
            </div>
          </div>
        </div>
      </div>
      <main-footer />
    </div>
  </div>
  <!-- /Page Wrapper -->
  <activities-list-modal></activities-list-modal>
</template>

<script setup>
import { ref, watch } from "vue";
import { debounce } from "lodash"; // Make sure to install lodash: npm install lodash

const title = ref("Уведомления");
const searchQuery = ref("");
const debouncedSearch = ref("");

const updateSearch = debounce((value) => {
  debouncedSearch.value = value;
}, 300); // 300ms debounce

watch(searchQuery, (newVal) => {
  updateSearch(newVal);
});
</script>