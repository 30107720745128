<template>
  <nav class="main-sidebar">
    <ul>
      <li>
        <router-link
          to="/admin/analytics"
          :class="{ active: $route.path === '/admin/analytics' }"
          @click="deleteSlideNav"
        >
          <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M14.0659 1.8335H17.0287C18.2556 1.8335 19.2499 2.88386 19.2499 4.18013V7.31014C19.2499 8.60641 18.2556 9.65678 17.0287 9.65678H14.0659C12.839 9.65678 11.8447 8.60641 11.8447 7.31014V4.18013C11.8447 2.88386 12.839 1.8335 14.0659 1.8335Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.97121 1.8335H6.93393C8.16091 1.8335 9.15514 2.88386 9.15514 4.18013V7.31014C9.15514 8.60641 8.16091 9.65678 6.93393 9.65678H3.97121C2.74422 9.65678 1.75 8.60641 1.75 7.31014V4.18013C1.75 2.88386 2.74422 1.8335 3.97121 1.8335ZM3.97121 12.3435H6.93393C8.16091 12.3435 9.15514 13.3939 9.15514 14.6902V17.8202C9.15514 19.1156 8.16091 20.1668 6.93393 20.1668H3.97121C2.74422 20.1668 1.75 19.1156 1.75 17.8202V14.6902C1.75 13.3939 2.74422 12.3435 3.97121 12.3435ZM17.0288 12.3435H14.0661C12.8391 12.3435 11.8449 13.3939 11.8449 14.6902V17.8202C11.8449 19.1156 12.8391 20.1668 14.0661 20.1668H17.0288C18.2558 20.1668 19.25 19.1156 19.25 17.8202V14.6902C19.25 13.3939 18.2558 12.3435 17.0288 12.3435Z"
              fill="white"
            />
          </svg>
          <p>Аналитика</p>
        </router-link>
      </li>
      <li>
        <router-link to="/settings" @click="deleteSlideNav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            class="ico-settings"
          >
            <path
              d="M7.10417 1.78134C7.45917 0.318005 9.54083 0.318005 9.89583 1.78134C9.9491 2.00117 10.0535 2.20532 10.2006 2.37717C10.3477 2.54902 10.5332 2.68372 10.7422 2.77029C10.9512 2.85687 11.1776 2.89288 11.4032 2.8754C11.6287 2.85791 11.8469 2.78742 12.04 2.66967C13.3258 1.88634 14.7983 3.358 14.015 4.64467C13.8974 4.83772 13.827 5.05579 13.8096 5.28115C13.7922 5.50651 13.8281 5.73281 13.9146 5.94165C14.0011 6.1505 14.1356 6.33599 14.3073 6.48305C14.4789 6.63011 14.6829 6.73459 14.9025 6.788C16.3658 7.143 16.3658 9.22467 14.9025 9.57967C14.6827 9.63294 14.4785 9.73736 14.3067 9.88443C14.1348 10.0315 14.0001 10.2171 13.9135 10.426C13.827 10.635 13.791 10.8615 13.8084 11.087C13.8259 11.3125 13.8964 11.5307 14.0142 11.7238C14.7975 13.0097 13.3258 14.4822 12.0392 13.6988C11.8461 13.5813 11.6281 13.5109 11.4027 13.4934C11.1773 13.476 10.951 13.512 10.7422 13.5984C10.5333 13.6849 10.3479 13.8195 10.2008 13.9911C10.0537 14.1628 9.94925 14.3667 9.89583 14.5863C9.54083 16.0497 7.45917 16.0497 7.10417 14.5863C7.0509 14.3665 6.94648 14.1624 6.79941 13.9905C6.65233 13.8187 6.46676 13.684 6.25779 13.5974C6.04882 13.5108 5.82236 13.4748 5.59685 13.4923C5.37133 13.5098 5.15313 13.5803 4.96 13.698C3.67417 14.4813 2.20167 13.0097 2.985 11.723C3.10258 11.53 3.17296 11.3119 3.1904 11.0865C3.20785 10.8612 3.17187 10.6349 3.08539 10.426C2.99892 10.2172 2.86438 10.0317 2.69273 9.88463C2.52107 9.73757 2.31714 9.63308 2.0975 9.57967C0.634167 9.22467 0.634167 7.143 2.0975 6.788C2.31733 6.73474 2.52148 6.63032 2.69333 6.48325C2.86518 6.33617 2.99988 6.1506 3.08645 5.94163C3.17303 5.73266 3.20904 5.5062 3.19156 5.28068C3.17407 5.05517 3.10359 4.83696 2.98583 4.64384C2.2025 3.358 3.67417 1.8855 4.96083 2.66884C5.79417 3.1755 6.87417 2.72717 7.10417 1.78134Z"
              stroke="#005534"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 8.18384C6 8.84688 6.26339 9.48276 6.73223 9.95161C7.20107 10.4204 7.83696 10.6838 8.5 10.6838C9.16304 10.6838 9.79893 10.4204 10.2678 9.95161C10.7366 9.48276 11 8.84688 11 8.18384C11 7.5208 10.7366 6.88491 10.2678 6.41607C9.79893 5.94723 9.16304 5.68384 8.5 5.68384C7.83696 5.68384 7.20107 5.94723 6.73223 6.41607C6.26339 6.88491 6 7.5208 6 8.18384Z"
              stroke="#005534"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>Настройки</p>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const deleteSlideNav = () => {
  document.body.classList.remove("slide-nav");
};
</script>
