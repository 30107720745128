import { defineStore } from "pinia";
import apiClient from "@/axios";
import { baseUrl } from "@/config";
export const useDealStore = defineStore("deal", {
  state: () => ({
    deal: {},
    activities: [],
  }),
  getters: {
    getDeal: (state) => state.deal,
    getActivities: (state) => state.activities.flatMap((item) => item),
  },
  actions: {
    async fetchDeal(leadId) {
      const response = await apiClient.get(`${baseUrl}/deals/${leadId}`);
      this.deal = response.data;
    },
    async fetchActivities(leadId) {
      const response = await apiClient.get(
        `${baseUrl}/activity-histories/${leadId}`
      );
      this.activities = response.data.map((item) => item.activities);
    },
    async setCommission(leadId, data) {
      const response = await apiClient.post(
        `${baseUrl}/deals/${leadId}/commissions`,
        {
          commission: +data.commission,
          mangerId: this.deal.fr_manager_lk.uuid,
        }
      );
      return response;
    },
    async getCommission(leadId, mangerId) {
      const response = await apiClient.get(
        `${baseUrl}/deals/${leadId}/${mangerId}/commissions`
      );
      return response;
    },
    async getAnswers() {
      const response = await apiClient.post(
        `${baseUrl}/deals/answers/commissions`
      );
      return response;
    },
  },
});
